




















































































































































import Vue from "vue";
import { Laboratory } from "@/code/models/lab/Laboratory";
import Swal from "sweetalert2";
import { LaboratoryService } from "@/code/services/lab/laboratoryService";
import moment from 'moment';
import { Confirm } from "@/code/util/swalUtil";
import { EventBus } from "@/code/util/eventBus";

export default Vue.extend({

  data() {
    return {
      idAccount: 0,
      labsList: [] as Array<Laboratory>,
      selectedLab: new Laboratory(0, {}),
      filterText: '',
      loadingLabs: false,
      headersLabs: [
        {
          text: 'Id',
          value: 'id',
          filterable: false
        },
        {
          text: 'Nome',
          value: 'name',
          filterable: true
        },
        {
          text: 'E-mail',
          value: 'email',
          filterable: true
        },
        {
          text: 'Atualizado em',
          value: 'modified',
        },
        { text: 'Ações', value: 'actions', sortable: false }
      ]
    }
  },

  mounted() {
    try {
      this.idAccount = parseInt(this.$route.params.id);
    } catch (ex: any) {
      this.idAccount = 0;
    }
    const that = this;
    if (this.idAccount && this.idAccount > 0) {
      this.loadLabs();
    } else {
      Swal.fire({
        title: 'Parâmetro inválido',
        text: 'A loja informada é invalida!',
        icon: 'error'
      })
      .then(
        (result) => {
          that.$router.replace({ name: 'assistentAction' });
        }
      )
    }
  },

  methods: {
    loadLabs: async function() {
      this.loadingLabs = true;
      const service = new LaboratoryService();

      this.labsList = await service.getAll();
      this.loadingLabs = false;
    },
    getFormattedDate: function(date) {
      return date ? moment.utc(date).format('DD/MM/YYYY HH:mm') : '';
    },
    editLab: function(lab: Laboratory) {
      this.selectedLab = new Laboratory(lab.id, lab);
    },
    saveLab: async function() {
      const service = new LaboratoryService();
      if (this.selectedLab.id === 0) {
        const saved = await service.save(this.selectedLab);
        if (saved && saved.id > 0) {
          this.labsList.push(saved);
        }
      } else {
        const updated = await service.update(this.selectedLab.id, this.selectedLab)
        if (updated && updated.id > 0) {
          let users = this.labsList.filter((u) => u.id === updated.id);
          if (users && users.length > 0) {
            users[0] = updated;
          } else {
            this.loadLabs();
          }
          this.selectedLab = new Laboratory(0, {});
        }
      }
    },
    cancelLab: function() {
      const that = this;
      Confirm.fire({
        title: "Há dados não salvos",
        text: "Os dados não salvos serão perdidos. Deseja continuar?"
      })
      .then(async (result) => {
        if (result && result.isConfirmed) {
          that.selectedLab = new Laboratory(0, {});
        }
      });
    },
    deleteLab: function(lab: Laboratory) {
      const that = this;
      Confirm.fire(`Deseja realmente excluir o laboratório ${lab.name}?`)
      .then(async (result) => {
        if (result && result.isConfirmed) {
          const service = new LaboratoryService();
          const success = await service.delete(lab.id);
          if (success) {
            const toRemove = that.labsList.filter((u) => u.id === lab.id);
            if (toRemove && toRemove.length > 0) {
              that.labsList.splice(that.labsList.indexOf(toRemove[0]));
            }
          }
        }
      });
    },
    nextTab: async function() {
      const dirty = await (this.$refs.formLabs as any).flags.dirty;
      if (dirty) {
        Confirm.fire('Os dados informados serão perdidos. Deseja avançar à próxima etapa?')
        .then(result => {
          if (result && result.isConfirmed) {
            EventBus.$emit('nextAssistentRoute');
          }
        });
      } else {
        EventBus.$emit('nextAssistentRoute');
      }
    },
    previousTab: async function() {
      const dirty = await (this.$refs.formLabs as any).flags.dirty;
      if (dirty) {
        Confirm.fire('Os dados informados serão perdidos. Deseja voltar à etapa anterior?')
        .then(result => {
          if (result && result.isConfirmed) {
            EventBus.$emit('previousAssistentRoute');
          }
        });
      } else {
        EventBus.$emit('previousAssistentRoute');
      }
    }
  }

})
