import { Laboratory } from "@/code/models/lab/Laboratory";
import { BaseService } from "@/code/services/base/baseService";

export class LaboratoryService extends BaseService<Laboratory> {
  
  constructor() {
    super(Laboratory);
  }

  getUrl(): String {
    return "labs";
  }

}