import { Model } from "../Model";

export class Laboratory extends Model {

  constructor(id: number, attributes: any) {
    super(id);
    this.name = attributes.name;
    this.email = attributes.email;
    this.modified = attributes.modified;
  }

  name: String;
  email: String;
  modified: Date;

}